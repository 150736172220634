import React from "react";
import { NavLink } from "react-router-dom";
import { useUser } from "../../../contexts/UserContext";
import Deconnexion from "./Deconnexion";
import "./navigation.scss";

const Navigation = () => {
    const user = useUser();

    return (
        <nav>
            <div className="logo">
                <NavLink className="nav-link" title="La timeline" to="/home">
                    <h1>oHminod Network</h1>
                </NavLink>
            </div>
            <ul>
                <li>
                    <NavLink className="nav-link" to="/membre">
                        {user ? (
                            <img src={user.avatar} alt="Profile" />
                        ) : (
                            <i
                                className="fa-regular fa-user"
                                title="Profile"
                            ></i>
                        )}
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" to="/">
                        <Deconnexion />
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Navigation;
